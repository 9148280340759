import { useAuth } from "hooks/auth";
import SwaggerUI from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";
import { Button } from "react-bootstrap";
import BearerToken from "components/bearer-token";

export function SwaggerWrapper() {
  const auth = useAuth();
  return (
    <>
      <SwaggerUI url="swagger/sparket-admin.v1.yaml" />;
      <div style={{ textAlign: "center" }}>
        <BearerToken />
      </div>
      <br />
      <div style={{ textAlign: "center" }}>
        <Button onClick={() => auth.signOut()}>Sign Out</Button>
      </div>
    </>
  );
}

export default SwaggerWrapper;
