import Spinner from "react-bootstrap/Spinner";

const LoadingSpinner = () =>
  (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh"
      }}
    >
      <Spinner role="loading-spinner" animation="border" variant="warning" />
    </div>
  );

export default LoadingSpinner;
