import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserMeResponse from "interfaces/user-me-response";
import { FAILED, LOADING, SUCCEEDED } from "utils/constants";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";

interface UserState {
  fetchState: "idle" | "loading" | "succeeded" | "failed";
  data?: UserMeResponse;
  error?: string;
}

const initialState: UserState = {
  fetchState: "idle",
};

export const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  const response = await getRequest("/v2/users/me");
  return response || {};
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchUser.pending, (state, _) => {
      state.fetchState = LOADING;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.data = action.payload;
      state.fetchState = SUCCEEDED;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.fetchState = FAILED;
      state.error = action.error.message;
    });
  },
});

export const getUser = (state: RootState) => {
  return state.user;
};

export const getUserFetchState = (state: RootState) => {
  return state.user.fetchState;
};

const userReducer = slice.reducer;
export default userReducer;
