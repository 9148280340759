import { useState } from "react";
import { useSelector } from "react-redux";
import {
  CHeader,
  CHeaderToggler,
  CHeaderNav,
  CContainer,
  CLink,
  CHeaderText,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { AppHeaderDropdown } from "./index";
import Select from "react-select";
import { useEffect } from "react";
import { IDLE, LOADING } from "utils/constants";
import AppBreadcrumb from "components/AppBreadcrumb";
import { cilMenu } from "@coreui/icons";
import { useHistory } from "react-router-dom";
import UserGroupsResponse from "interfaces/user-groups-response";
import { RootState } from "redux/store";
import { getGroupState, getSlugPath, setGroup } from "redux/slices/group-slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  getAvailableGroupsData,
  getAvailableGroupsStatus,
} from "redux/slices/available-groups-slice";
import { toggleSidebarShow } from "redux/slices/sidebar-slice";
import { sygnet } from "assets/icons/sygnet";
import { Spinner } from "react-bootstrap";

const AppHeader = (props: any) => {
  const [groupOptions, setGroupOptions] = useState([]);
  const dispatch = useAppDispatch();
  const groupSlugPath = useAppSelector(getSlugPath);
  const history = useHistory();
  const groupOptionSelectorValue = useSelector((state: RootState) => {
    if (state.currentGroup === undefined) {
      return {};
    }
    return { label: state.currentGroup.name, value: state.currentGroup.id };
  });
  const availableGroupsStatus = useAppSelector(getAvailableGroupsStatus);
  const availableGroupsData = useAppSelector(getAvailableGroupsData);
  const group = useAppSelector(getGroupState);

  useEffect(() => {
    if (availableGroupsStatus === IDLE || availableGroupsStatus === LOADING) {
      return;
    }
    let groupOptions: any = [];
    const groups = availableGroupsData;
    groups.forEach((group: UserGroupsResponse, index: number) => {
      groupOptions.push({ label: group.name, value: group.id });
    });
    setGroupOptions(groupOptions);
  }, [availableGroupsStatus, availableGroupsData, dispatch]);

  return (
    <CHeader position="sticky" className="mb-4 px-3">
      <CContainer fluid className="justify-content-between">
        <div className="d-flex py-1">
          <CHeaderNav className="mr-auto">
            <CHeaderToggler
              className="ps-1"
              onClick={() => {
                dispatch(toggleSidebarShow());
              }}
            >
              <CIcon icon={cilMenu} size="lg" />
            </CHeaderToggler>
          </CHeaderNav>
          <CHeaderNav className="d-flex d-sm-none ms-2 py-1">
            <CIcon icon={sygnet} height={35} />
          </CHeaderNav>
          <CHeaderNav className="d-none d-sm-flex ms-2 py-1">
            <CHeaderText>Admin Console</CHeaderText>
          </CHeaderNav>
        </div>
        <CHeaderText className="d-none d-lg-flex">
          {group.logoIsStale ? (
            <Spinner
              animation="grow"
              style={{ color: "var(--sparket-gold)" }}
            />
          ) : (
            <img
              height="48"
              src={group.settings.logo_url}
              alt="group-logo"
            ></img>
          )}
        </CHeaderText>
        <CHeaderNav className="d-md-down-none ml-auto mr-0">
          <div style={{ width: "12em" }} data-testid="group-dropdown-picker">
            <Select
              value={groupOptionSelectorValue}
              options={groupOptions}
              isLoading={groupOptions.length === 0}
              onChange={(event: any) => {
                const groups = availableGroupsData;
                const found = groups.find((grp) => grp.id === event.value);
                dispatch(setGroup(found));
                history.push("/pools");
              }}
            ></Select>
          </div>
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>

      <CContainer fluid className="py-2">
        <AppBreadcrumb />
        <CHeaderNav className="text-end mx-2">
          <CLink
            href={process.env.REACT_APP_BASE_APP_URL + groupSlugPath}
            target="_blank"
          >
            {"sparket.app" + groupSlugPath}
          </CLink>
        </CHeaderNav>
      </CContainer>
    </CHeader>
  );
};

export default AppHeader;
