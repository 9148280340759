import {
  cibSuperuser,
  cibSwagger,
  cilBank,
  cilBook,
  cilCalendar,
  cilChart,
  cilGroup,
  cilSettings,
  cilSoccer,
  cilSofa,
  cilUser
} from "@coreui/icons";
import { CNavGroup, CNavItem, CNavTitle } from "@coreui/react";
import { cilSpreadsheet } from "@coreui/icons/js/free";

const navigation = [
  {
    component: CNavTitle,
    name: "Pari-mutuel",
  },
  {
    component: CNavItem,
    name: "Manage Pools",
    to: "/parent-pools",
    icon: cilSofa,
  },
  {
    component: CNavItem,
    name: "View Pools",
    to: "/pools",
    icon: cilSoccer,
  },
  {
    component: CNavItem,
    name: "Events",
    to: "/events",
    icon: cilCalendar,
  },
  {
    component: CNavItem,
    name: "Contests",
    to: "/contests",
    icon: cilSpreadsheet,
  },
  {
    component: CNavItem,
    name: "Bankroll",
    to: "/bankroll",
    icon: cilBank,
  },
  {
    component: CNavItem,
    name: "Analytics",
    to: "/analytics",
    icon: cilChart,
  },
  {
    component: CNavItem,
    name: "Users",
    to: "/users",
    icon: cilUser,
  },
  {
    component: CNavItem,
    name: "Group Settings",
    to: "/group-settings",
    icon: cilSettings,
  },
  {
    component: CNavItem,
    name: "Help",
    to: "/help",
    icon: cilBook,
  },
];

export default navigation;

export const superAdminNav = [
  {
    component: CNavGroup,
    name: "Super Admin",
    to: "/",
    icon: cibSuperuser,
    items: [
      {
        icon: cibSwagger,
        component: CNavItem,
        name: "SwaggerUI",
        to: "/swagger-ui",
      },
      {
        icon: cilGroup,
        component: CNavItem,
        name: "New Group",
        to: "/group-create",
      }
    ],
  },
];
