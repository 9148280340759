import React from "react";
import { CFooter } from "@coreui/react";

const AppFooter = (props: any) => {
  return (
    <CFooter>
      <div className="px-3">
        <span>The Social Betwork™</span>
      </div>
      <div className="mfs-auto">
        <span className="px-3">Powered by SPARKET</span>
      </div>
    </CFooter>
  );
};

export default React.memo(AppFooter);
