import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, fetchGroupStatus } from "redux/slices/group-slice";
import { useEffect } from "react";

const CheckGroupStatus = () => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (group.id) {
      dispatch(fetchGroupStatus(group.id));
    }
  }, [group.id, dispatch]);
};

export default CheckGroupStatus;
