import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { PoolsPageUserSettings } from "user/pools-page-user-settings";
import { Pool } from "interfaces/pool";
import { ParentPool } from "interfaces/parent-pool";
import { USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME } from "utils/constants";
import { UserSettings } from "user/user-settings";
import { ContestsPageUserSettings } from "user/contests-page-user-settings";

const loadInitialState: () => UserSettings = () => {
  const savedSettings = localStorage.getItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME);

  if (savedSettings) {
    const parsedSettings = JSON.parse(savedSettings);
    if (!parsedSettings.poolsPageSettings) {
      parsedSettings.poolsPageSettings = {};
    }
    if (parsedSettings.parentPoolPageSettings) {
      parsedSettings.parentPoolPageSettings = {};
    }
    if (!parsedSettings.contestPageSettings) {
      parsedSettings.contestPageSettings = {};
    }

    if (parsedSettings.poolsPageSettings.statusFilter === "all") {
      parsedSettings.poolsPageSettings.statusFilter = null;
    }
    if (parsedSettings.parentPoolPageSettings.statusFilter === "all") {
      parsedSettings.parentPoolPageSettings.statusFilter = null;
    }
    return parsedSettings;
  } else {
    return {
      poolsPageSettings: {
        statusFilter: null,
        activityNameFilter: null,
        eventFilter: null,
        sort: null,
        createdByUserId: null,
      },
      parentPoolPageSettings: {
        statusFilter: null,
        activityNameFilter: null,
        eventFilter: null,
        sort: null,
        createdByUserId: null,
      },
      contestPageSettings: {
        sort: null
      }
    } as UserSettings;
  }
};

export const getUserSettingsState = (state: RootState) => state.userSettings;

const initialState: UserSettings = loadInitialState();

const slice = createSlice({
  name: "userSettings",
  initialState: initialState,
  reducers: {
    updatePoolsPageSettings: (state, action: PayloadAction<PoolsPageUserSettings<Pool>>) => {
      state.poolsPageSettings = action.payload;
      localStorage.setItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME, JSON.stringify(state));
    },
    updateParentPoolsPageSettings: (state, action: PayloadAction<PoolsPageUserSettings<ParentPool>>) => {
      state.parentPoolPageSettings = action.payload;
      localStorage.setItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME, JSON.stringify(state));
    },
    updateContestsPageSettings: (state, action: PayloadAction<ContestsPageUserSettings>) => {
      state.contestPageSettings = action.payload;
      localStorage.setItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME, JSON.stringify(state));
    }
  }
});

export default slice.reducer;
export const {
  updatePoolsPageSettings,
  updateParentPoolsPageSettings,
  updateContestsPageSettings
} = slice.actions;
