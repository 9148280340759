import { configureStore } from "@reduxjs/toolkit";
import availableGroupsReducer from "./slices/available-groups-slice";
import groupReducer from "./slices/group-slice";
import sidebarReducer from "./slices/sidebar-slice";
import userReducer from "./slices/user-slice";
import userSettingsReducer from "./slices/user-settings-slice";

const store = configureStore({
  reducer: {
    sidebar: sidebarReducer,
    availableGroups: availableGroupsReducer,
    currentGroup: groupReducer,
    user: userReducer,
    userSettings: userSettingsReducer
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
