import React from "react";
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { Button } from "react-bootstrap";
import { useAuth } from "hooks/auth";
import { cilUser } from "@coreui/icons";

const AppHeaderDropdown = (props: any) => {
  const auth = useAuth();
  return (
    <CDropdown className="c-header-nav-items mx-2">
      <CDropdownToggle
        color="light"
        className="c-header-nav-link"
        caret={false}
      >
        <CIcon icon={cilUser} className="cil-user"></CIcon>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem color="light" className="text-center">
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon icon={cilUser} className="me-2" />
          {auth.getEmail()}
        </CDropdownItem>
        <CDropdownItem>
          <Button className="w-100" onClick={() => auth.signOut()}>
            Sign Out
          </Button>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default AppHeaderDropdown;
