import ProtectedRoute from "components/protected-route";
import SuperAdminRoute from "components/super-admin-route";
import { AppLayout } from "containers";
import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import SwaggerWrapper from "views/pages/swagger";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Pages
const SignIn = React.lazy(() => import("./views/pages/sign-in/SignIn"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const NoGroupsErrorPage = React.lazy(
  () => import("./views/pages/no-availalbe-groups-error")
);

const App = () => {
  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/sign-in"
            render={(props: any) => <SignIn {...props} />}
          />
          <Route
            exact
            path="/404"
            render={(props: any) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            render={(props: any) => <Page500 {...props} />}
          />
          <Route exact path="/no-groups-error" component={NoGroupsErrorPage} />
          <SuperAdminRoute path="/swagger-ui" render={SwaggerWrapper} />
          <ProtectedRoute path="/" render={AppLayout} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  );
};

export default App;
