import { Auth } from "aws-amplify";
// Auth config data for using Amazon Cognito with Amplify
//
// NOTE: Ideally values should be read from env instead of hardcoded here
// ...Either way, values will be bundled into the JS anyway, so a user can still see them.
// ...Therefore, we just use a Client ID here. No Client Secret in the front end.

const authConfig = {
  Auth: {
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    authenticationFlowType: "USER_SRP_AUTH"
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: [
      "phone",
      "openid",
      "profile",
      "email",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_URL,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_URL,
    responseType: "code" // 'token' | 'code' // note that REFRESH token will only be generated when the responseType is code
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: process.env.REACT_APP_BACKEND_URL,
        custom_header: async () => {
          try {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
              "x-client-id": "1"
            };
          } catch (e) {
            return {};
          }
        }
      }
    ]
  }
};

export default authConfig;
