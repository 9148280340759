import React from "react";
import { Link, useLocation } from "react-router-dom";

import routes from "../routes";

import { CBreadcrumb, CBreadcrumbItem } from "@coreui/react";

const AppBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (prev: string, curr: string, routes: any) => {
    const pathname = `${prev}/${curr}`;
    const currentRoute = routes.find((route: any) => route.path === pathname);
    if (currentRoute === undefined) {
      return curr;
    }
    return currentRoute.name;
  };

  const getBreadcrumbs = (location: string) => {
    const breadcrumbs: any = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      breadcrumbs.push({
        pathname: currentPathname,
        name: getRouteName(prev, curr, routes),
        active: index + 1 === array.length,
      });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      <CBreadcrumbItem>
        <Link to="/">Home</Link>
      </CBreadcrumbItem>
      {breadcrumbs.map((breadcrumb: any, index: number) => {
        return (
          <CBreadcrumbItem active={breadcrumb.active} key={index}>
            {breadcrumb.active ? (
              breadcrumb.name
            ) : (
              <Link to={breadcrumb.pathname}>{breadcrumb.name}</Link>
            )}
          </CBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(AppBreadcrumb);
