import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SidebarState {
  show: boolean;
  unfoldable: boolean;
  narrow: boolean;
}

const initialState: SidebarState = {
  show: true,
  unfoldable: false,
  narrow: false,
};

const slice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebarShow: (state) => {
      state.show = !state.show;
    },
    hideSidebar: (state) => {
      state.show = false;
    },
    showSidebar: (state) => {
      state.show = true;
    },
    wideSidebar: (state) => {
      state.narrow = false;
    },
    toggleSidebarFold: (state) => {
      state.unfoldable = !state.unfoldable;
    },
    toggleSidebarNarrow: (state) => {
      state.narrow = !state.narrow;
    },
  },
});

export const {
  toggleSidebarShow,
  hideSidebar,
  showSidebar,
  toggleSidebarFold,
  toggleSidebarNarrow,
  wideSidebar,
} = slice.actions;

export const getSidebarShow = (state: RootState) => {
  return state.sidebar.show;
};
export const getSidebarFold = (state: RootState) => {
  return state.sidebar.unfoldable;
};
export const getSidebarNarrow = (state: RootState) => {
  return state.sidebar.narrow;
};

const sidebarReducer = slice.reducer;
export default sidebarReducer;
