import InitializeUserState from "app-setup/initialize-user-state";
import { useAuth } from "hooks/auth";
import { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import {
  getAvailableGroupsData,
  getAvailableGroupsStatus,
} from "redux/slices/available-groups-slice";
import { SUCCEEDED } from "utils/constants";
import LoadingSpinner from "./loading-spinner";
import CheckGroupStatus from "app-setup/check-group-status";
import InitializeGroupState from "app-setup/initialize-group-state";

function ProtectedRoute({ render: Component, ...restOfProps }: any) {
  InitializeUserState();
  const location = useLocation();
  InitializeGroupState(location.pathname);
  CheckGroupStatus();
  const history = useHistory();
  const availableGroupsStatus = useAppSelector(getAvailableGroupsStatus);
  const availableGroupsData = useAppSelector(getAvailableGroupsData);

  useEffect(() => {
    if (availableGroupsStatus !== SUCCEEDED) {
      return;
    }

    if (availableGroupsData.length === 0) {
      history.push("/no-groups-error");
    }
  }, [availableGroupsStatus, availableGroupsData, history]);
  const auth = useAuth();
  if (auth.pending) {
    return <LoadingSpinner />;
  } else {
    return (
      <Route
        {...restOfProps}
        render={(props) =>
          auth.signedIn ? <Component {...props} /> : <Redirect to="/sign-in" />
        }
      />
    );
  }
}

export default ProtectedRoute;
