import { API } from "aws-amplify";

const API_NAME = "api";

export const getRequest = (path: string, options?: any) => {
  const myInit = {
    queryStringParameters: options?.queryParams,
    response: false || options?.response,
    ...(options?.responseType && { responseType: options?.responseType }),
  };
  return API.get(API_NAME, path, myInit);
};

export const postRequest = (path: string, options?: any) => {
  const myInit = {
    body: options.body,
  };
  return API.post(API_NAME, path, myInit);
};

export const putRequest = (path: string, options?: any) => {
  const myInit = {
    body: options.body,
  };
  return API.put(API_NAME, path, myInit);
};

export const patchRequest = (path: string, options?: any) => {
  const myInit = {
    body: options.body,
  };
  return API.patch(API_NAME, path, myInit);
};

export const deleteRequest = (path: string, options?: any) => {
  const myInit = {
    body: options?.body,
  };
  return API.del(API_NAME, path, myInit);
};
