import "react-app-polyfill/ie11"; // For IE 11 support
import "react-app-polyfill/stable";
import "core-js";
import "./polyfill";
import React from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { icons } from "assets/icons";
// @ts-ignore
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import store from "./redux/store";
import { ProvideAuth } from "hooks/auth";
import setupServer from "server/server";

if (
  process.env.REACT_APP_USE_LOCAL === "true" &&
  process.env.NODE_ENV !== "production"
) {
  setupServer(true);
}

// @ts-expect-error ts-migrate(2339) FIXME: Property 'icons' does not exist on type 'typeof Re... Remove this comment to see the full error message
React.icons = icons;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<
  Provider store={store}>
  <ProvideAuth>
    <App />
  </ProvideAuth>
</Provider>);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
