import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { CContainer } from "@coreui/react";

// routes config
import routes, { superAdminRoutes } from "../routes";
import SuperAdminRoute from "components/super-admin-route";
import LoadingSpinner from "components/loading-spinner";

const AppContent = (props: any) => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <Suspense fallback={<LoadingSpinner />}>
          <Switch>
            {routes.map((route, idx) => {
              return (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={(props: any) => <route.component {...props} />}
                />
              );
            })}
          </Switch>
          <Switch>
            {superAdminRoutes.map((route, idx) => {
              return (
                <SuperAdminRoute
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  render={(props: any) => <route.component {...props} />}
                />
              );
            })}
          </Switch>
        </Suspense>
      </CContainer>
    </main>
  );
};

export default React.memo(AppContent);
