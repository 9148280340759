import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const BearerToken = () => {
  const [token, setToken] = useState("");
  useEffect(() => {
    async function getToken() {
      try {
        const token = `Bearer ${(await Auth.currentSession())
          .getIdToken()
          .getJwtToken()}`;
        setToken(token);
      } catch (e) {
        return {};
      }
    }
    getToken();
  }, []);
  return (
    <>
      <p>Token to authorize access to protected endpoints</p>
      <div>{token}</div>
      <Button
        onClick={() => {
          var tempInput = document.createElement("input");
          (tempInput as any).style =
            "position: absolute; left: -1000px; top: -1000px";
          tempInput.value = token;
          document.body.appendChild(tempInput);
          tempInput.select();
          document.execCommand("copy");
          document.body.removeChild(tempInput);
        }}
      >
        <div>Copy Token</div>
      </Button>
    </>
  );
};

export default BearerToken;
