import React from "react";

import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

// sidebar nav config
import navigation, { superAdminNav } from "./_nav";
import AppSidebarNav from "./AppSidebarNav";
import { logoNegative } from "assets/icons/logo-negative";
import { sygnet } from "assets/icons/sygnet";
import {
  getSidebarFold,
  getSidebarNarrow,
  getSidebarShow,
  toggleSidebarNarrow,
  toggleSidebarShow,
} from "redux/slices/sidebar-slice";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getUser } from "redux/slices/user-slice";

const AppSidebar = () => {
  const dispatch = useAppDispatch();
  const unfoldable = useAppSelector(getSidebarFold);
  const sidebarShow = useAppSelector(getSidebarShow);
  const sidebarNarrow = useAppSelector(getSidebarNarrow);
  const user = useAppSelector(getUser);

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      narrow={sidebarNarrow}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        if (visible !== sidebarShow) {
          dispatch(toggleSidebarShow());
        }
      }}
    >
      <CSidebarBrand
        className="d-flex"
        onClick={() => {
          dispatch(toggleSidebarNarrow());
        }}
      >
        <CIcon className="sidebar-brand-full" icon={logoNegative} height={35} />
        <CIcon className="sidebar-brand-narrow" icon={sygnet} height={35} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={navigation} />
          {user.data?.super_admin && (
            <AppSidebarNav role="superuser-nav" items={superAdminNav} />
          )}
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => {
          dispatch(toggleSidebarNarrow());
        }}
      />
    </CSidebar>
  );
};

export default React.memo(AppSidebar);
