import { useAppDispatch, useAppSelector } from "redux/hooks";
import { useEffect } from "react";
import { IDLE } from "utils/constants";
import { fetchUser, getUserFetchState } from "redux/slices/user-slice";

const InitializeUserState = () => {
  const dispatch = useAppDispatch();
  const userFetchState = useAppSelector(getUserFetchState);

  useEffect(() => {
    if (userFetchState === IDLE) {
      dispatch(fetchUser());
    }
  }, [userFetchState, dispatch]);
};

export default InitializeUserState;
