// Hook (auth.js)
import React, { useState, useEffect, useContext, createContext } from "react";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import authConfig from "hooks/authConfig";
import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { Hub } from "aws-amplify";

Amplify.configure(authConfig);

export const defaultUser = {
  signInUserSession: {
    idToken: {
      payload: {
        email: "",
        "custom:roles": "",
      },
    },
  },
  attributes: { "custom:roles": "" },
  signedIn: false,
  username: undefined,
};

const defaultAuth = {
  user: defaultUser,
  getEmail: () => "",
  signIn: ({ username, password }: any) => new Promise(() => null),
  signOut: () => new Promise(() => null),
  federatedSignIn: () => new Promise(() => null),
  googleSignIn: () => new Promise(() => null),
  pending: true,
  signedIn: false,
  amplifyAuth: Auth,
  getGroupIds: () => [""],
};

const authContext = createContext(defaultAuth);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
export function useProvideAuth() {
  const [user, setUser] = useState(defaultUser);
  const [pending, setPending] = useState(true);

  // Implementation below based directly on the examples from AWS docs
  // ref: https://docs.amplify.aws/lib/auth/social/q/platform/js#full-samples

  useEffect(() => {
    Auth.currentSession();
    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
        case "cognitoHostedUI":
          setPending(true);
          getUser().then((userData) => {
            setUser(userData);
            setPending(false);
          });
          break;
        case "signOut":
          setUser(defaultUser);
          setPending(false);
          break;
        case "signIn_failure":
        case "cognitoHostedUI_failure":
          // TODO: something went wrong
          setPending(false);
          break;
        default:
      }
    });

    getUser()
      .then((userData) => {
        setUser(userData);
        setPending(false);
      })
      .catch((err) => console.log(err));
  }, []);

  function getUser() {
    return Auth.currentAuthenticatedUser()
      .then((userData) => userData)
      .catch(() => {
        console.log("Not signed in");
      });
  }
  const signIn = ({ username, password }: any) => {
    return Auth.signIn({ username, password })
      .then((res) => {
        getUser().then((userData) => {
          setUser(userData);
          setPending(false);
        });
      })
      .catch((error) => {});
  };
  const federatedSignIn = () => {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Cognito,
    });
  };

  const googleSignIn = () => {
    return Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };
  const signOut = () => {
    return Auth.signOut().then((res) => {
      setUser(defaultUser);
    });
  };
  const getEmail = () => {
    try {
      return user.signInUserSession.idToken.payload.email;
    } catch (error) {
      console.log("Cannot get email from user", user, error);
      return "";
    }
  };
  function getGroupIds(): string[] {
    try {
      let groups = user.signInUserSession.idToken.payload["custom:roles"];
      return groups.split(";").map((group) => group.split(":")[1]);
    } catch (error) {
      console.log("Cannot get groups from user", user, error);
      return [];
    }
  }

  // Return the user object and auth helpers
  return {
    user,
    getEmail,
    signIn,
    signOut,
    federatedSignIn,
    googleSignIn,
    pending,
    signedIn: !pending && user && user !== defaultUser,
    amplifyAuth: Auth,
    getGroupIds,
  };
}
