import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CRow,
} from "@coreui/react";
import { signInUrl } from "utils/constants";
import { Link } from "react-router-dom";

const SignIn = (props: any) => {
  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCard className="p-4">
              <CCardBody>
                <h1>Sparket Admin</h1>
                <p className="text-muted">
                  Sign in with your admin account to get started
                </p>
                <CRow className="align-items-center">
                  <CCol xs="5">
                    <CButton
                      color="primary"
                      className="px-4"
                      onClick={() => {
                        window.location.href = signInUrl;
                      }}
                    >
                      Sign In
                    </CButton>
                  </CCol>
                  <CCol xs="7">
                    <Link to="/">Terms of Service</Link>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default SignIn;
