import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import { CBadge } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { wideSidebar } from "redux/slices/sidebar-slice";
import { useAppDispatch } from "redux/hooks";

const AppSidebarNav = ({ items }: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navLink = (name: string, icon: any, badge: any) => {
    return (
      <>
        <CIcon icon={icon} customClassName="nav-icon" />
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    );
  };

  const navItem = (item: any, index: number) => {
    const { component, name, badge, icon, ...rest } = item;
    const Component = component;
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
            activeClassName: "active",
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    );
  };
  const navGroup = (item: any, index: number) => {
    const { component, name, icon, to, ...rest } = item;
    const Component = component;
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon, null)}
        visible={location.pathname.startsWith(to)}
        onClick={() => {
          dispatch(wideSidebar());
        }}
        {...rest}
      >
        {item.items?.map((item: any, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
      </Component>
    );
  };

  return (
    <>
      {items &&
        items.map((item: any, index: number) =>
          item.items ? navGroup(item, index) : navItem(item, index)
        )}
    </>
  );
};

export default AppSidebarNav;
