import InitializeUserState from "app-setup/initialize-user-state";
import { useAuth } from "hooks/auth";
import { Redirect, Route } from "react-router-dom";
import { useAppSelector } from "redux/hooks";
import { getUser } from "redux/slices/user-slice";
import { LOADING } from "utils/constants";
import SignIn from "views/pages/sign-in/SignIn";
import LoadingSpinner from "./loading-spinner";

function SuperAdminRoute({ render: Component, ...restOfProps }: any) {
  InitializeUserState();
  const auth = useAuth();
  const user = useAppSelector(getUser);
  if (auth.pending || user.fetchState === LOADING) {
    return <LoadingSpinner />;
  }
  if (!auth.signedIn) {
    return <SignIn />;
  }
  const isSuperAdmin = user.data?.super_admin;
  if (isSuperAdmin) {
    return (
      <Route {...restOfProps} render={(props) => <Component {...props} />} />
    );
  } else {
    return <Redirect to="/404" />;
  }
}

export default SuperAdminRoute;
