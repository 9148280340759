import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserGroupsResponse from "interfaces/user-groups-response";
import { FAILED, LOADING, SUCCEEDED } from "utils/constants";
import { getRequest } from "utils/httpClient";
import { RootState } from "../store";

interface AvailableGroupsState {
  data: UserGroupsResponse[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | any;
}

export const initialState: AvailableGroupsState = {
  data: [],
  status: "idle",
  error: null
};

export const fetchAvailableGroups = createAsyncThunk(
  "availableGroups/fetchOptions",
  async () => {
    const response = await getRequest("/v2/users/groups/list");
    return response || [];
  }
);

const slice = createSlice({
  name: "availableGroups",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAvailableGroups.pending, (state, _) => {
      state.status = LOADING;
    });
    builder.addCase(fetchAvailableGroups.fulfilled, (state, action) => {
      state.data = (action.payload as UserGroupsResponse[])
        .sort((a, b) => a.name.localeCompare(b.name));
      state.status = SUCCEEDED;
    });
    builder.addCase(fetchAvailableGroups.rejected, (state, action) => {
      state.status = FAILED;
      state.error = action.error.message;
    });
  }
});

export const getAvailableGroupsData = (state: RootState) => {
  return state.availableGroups.data;
};

export const getAvailableGroupsStatus = (state: RootState) => {
  return state.availableGroups.status;
};

const availableGroupsReducer = slice.reducer;
export default availableGroupsReducer;
