import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserGroupsResponse from "interfaces/user-groups-response";
import { CURRENT_GROUP_ID, FAILED, IDLE, LOADING, sparketGold, SUCCEEDED } from "utils/constants";
import { getRequest } from "utils/httpClient";
import { local as localStorage } from "utils/local-storage";
import { RootState } from "../store";

interface GroupState extends UserGroupsResponse {
  status: string;
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  logoIsStale: boolean;
}

const INITIAL_PRIMARY_COLOR = sparketGold;

export const initialState: GroupState = {
  updated_at: "",
  created_at: "",
  id: "",
  name: "",
  fee: 0,
  currency_code: "",
  settings: {
    primary_color: INITIAL_PRIMARY_COLOR,
    logo_url: "",
    background_skin: "",
    group_owner: "",
    wager_wire: false
  },
  status: "",
  fetchStatusState: IDLE,
  uri: "",
  logoIsStale: false,
  suspended: false,
  contest_group: false,
  admin_role: false
};

export const fetchGroupStatus = createAsyncThunk(
  "group/fetchStatus",
  async (groupId: string, _) => {
    const response = await getRequest("/v2/groups/join/" + groupId, {
      responseType: "text"
    });
    return response;
  }
);

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroupStatus: (state, action) => {
      state.status = action.payload;
    },
    removeGroupSettings: (state) => {
      state.settings = initialState.settings;
    },
    setLogo: (state) => {
      state.logoIsStale = false;
      const suffix = state.settings.logo_url ? "?" + new Date().getTime() : "";
      state.settings.logo_url += suffix;
    },
    logoUrlIsStale: (state) => {
      state.logoIsStale = true;
    },
    setGroup: (state, action) => {
      if (!action.payload) {
        return state;
      }
      localStorage.setItem(CURRENT_GROUP_ID, action.payload.id);
      state.id = action.payload.id;
      state.updated_at = action.payload.updated_at;
      state.currency_code = action.payload.currency_code;
      state.created_at = action.payload.created_at;
      state.name = action.payload.name;
      state.fee = action.payload.fee;
      state.settings = action.payload.settings;
      state.uri = action.payload.uri;
      state.suspended = action.payload.suspended;
      state.contest_group = action.payload.contest_group;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchGroupStatus.pending, (state, action) => {
      state.fetchStatusState = LOADING;
    });
    builder.addCase(fetchGroupStatus.fulfilled, (state, action) => {
      state.status = action.payload;
      state.fetchStatusState = SUCCEEDED;
    });
    builder.addCase(fetchGroupStatus.rejected, (state, action) => {
      // TODO: Decide if we want to add toast package to use here
      // toast.error("Something went wrong");
      console.error("Failed to get group");
      state.fetchStatusState = FAILED;
    });
  }
});

export const {
  setGroup,
  removeGroupSettings,
  setGroupStatus,
  setLogo,
  logoUrlIsStale
} = slice.actions;

export const getGroupState = (state: RootState) => {
  return state.currentGroup;
};

export const getGroupStyles = (state: RootState) => {
  return getGroupState(state).settings;
};

export const getGroupStatus = (state: RootState) => {
  return getGroupState(state).status;
};

export const getSlugPath = (state: RootState) => {
  const uri = getGroupState(state).uri;
  return "/g/" + uri;
};

export const getSlugUrl = (state: RootState) => {
  const baseUrl = process.env.REACT_APP_HOST_URL;
  const uri = getGroupState(state).uri;
  return baseUrl + "g/" + uri;
};

export const getGroupId = (state: RootState) => {
  return getGroupState(state).id;
};

export const getGroupById = (state: RootState, groupId: string) => {
  return state.availableGroups.data.find(g => g.id === groupId);
};

const groupReducer = slice.reducer;
export default groupReducer;
