import authConfig from "hooks/authConfig";
import { EventStatus } from "views/events/interfaces";

export const signInUrl =
  "https://" +
  process.env.REACT_APP_OAUTH_DOMAIN +
  "/login?client_id=" +
  process.env.REACT_APP_COGNITO_CLIENT_ID +
  "&response_type=" +
  authConfig.oauth.responseType +
  "&scope=" +
  authConfig.oauth.scope.join("+") +
  "&redirect_uri=" +
  process.env.REACT_APP_COGNITO_REDIRECT_URL;

type PoolStatusProps = {
  name: string;
  color: string;
};

interface IPoolStatusMap {
  [key: number]: PoolStatusProps;
}

const PoolStatusMap: IPoolStatusMap = {
  1: { name: "pending", color: "warning" },
  2: { name: "open", color: "info" },
  3: { name: "live", color: "primary" },
  4: { name: "calculating", color: "info" },
  5: { name: "settled", color: "success" },
};
// Funky -1 assignment required by backend definition of deleted status
PoolStatusMap[-1] = { name: "deleted", color: "danger" };
export const POOL_STATUS_MAP = PoolStatusMap;

export const POOL_STATUS = {
  PENDING: 1,
  OPEN: 2,
  LIVE: 3,
  CALCULATING: 4,
  SETTLED: 5,
  CANCELLED: -1,
};

export interface PoolStatusWarning {
  message: string;
  color: string;
}
// Each entry refers to the warning message that should be displayed when the user
// tries to change the the status of the pool to the index of the message
// Eg: when the user tries to change the status of a pool to index = 0 (status = 1 because we always do pool.status - 1)
// the message "Changing the pool status to pending removes user betting functionality from the pool. Are you sure?" should be displayed because when
// the status of a pool changes to pending no more bets by users can be placed on it
export const POOL_STATUS_WARNINGS: PoolStatusWarning[] = [
  {
    message:
      "Changing the pool status to pending removes user betting functionality from the pool. Are you sure? (The server will automatically change pools to open on the open timestamp of the pool so make sure to change the open time.)",
    color: "warning",
  },
  {
    message:
      "Changing the pool status to open allows user betting functionality on the pool. Are you sure? (A pool in the open state can be changed back to pending)",
    color: "warning",
  },
  {
    message:
      "Changing the pool status to live is an irreversible action which locks all user betting. Are you sure?",
    color: "danger",
  },
];

export const sparketGold = "#FFCD00";
export const sparketGreen = "#D5F1DE";

export const EVENT_STATUS: EventStatus = {
  pending: { color: "warning" },
  open: { color: "info" },
  complete: { color: "success" },
};

/*
Status values
1: pending
2: open
3: live - last chance to edit pool
4: calculating - pool edits are disabled
5: settled
*/
export const STATUS_POOL_EDITS_DISABLE = 4;
export const CURRENT_GROUP_ID = "currentGroupId";
export const USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME = "userSettings";
export const SUCCEEDED = "succeeded";
export const LOADING = "loading";
export const FAILED = "failed";
export const IDLE = "idle";

// Utility styles
export const TABLE_COL_P = "py-3";

// Users
export const USER_TYPES = ["Member", "Admin"];

// Dropdown Util
export const ALL_OPT_VAL = "All";

export const SUSPENSIONS_API_PATH = "/v2/suspensions";

export enum CONTEST_STATE {
  PENDING = "pending",
  OPEN = "open",
  LIVE = "live",
  SETTLED = "settled"
}

export const CONTEST_STATE_WARNINGS: PoolStatusWarning[] = [
  {
    message:
      "Users will no longer be able to enter this contest, and it will open automatically after the ‘start’ time",
    color: "warning",
  },
  {
    message:
      "This contest will now be visible for users to enter, and it will move to ‘live’ automatically after the ‘end’ time",
    color: "warning",
  },
  {
    message:
      "Users will no longer be able to enter this contest",
    color: "danger",
  },
  {
    message:
      "This contest will be finalized",
    color: "danger",
  },
];

export const CONTEST_STATE_ORDER = {
  [CONTEST_STATE.PENDING]: 1,
  [CONTEST_STATE.OPEN]: 2,
  [CONTEST_STATE.LIVE]: 3,
  [CONTEST_STATE.SETTLED]: 4
};
